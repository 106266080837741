export default {
  "general": {
    "button": {
      "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["තවත් හදාරන්න"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපව අමතන්න"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඊළඟ"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කලින්"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ආපසු"])}
    },
    "languages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉංග්රීසි"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["චීන"])},
      "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මැලේ"])},
      "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දෙමළ"])},
      "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["බුරුම"])},
      "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සිංහල"])},
      "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["බෙංගාලි"])}
    }
  },
  "toasts": {
    "logout": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබට පිටවීමට අවශ්‍ය බව විශ්වාසද?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබ සාර්ථකව ලොග් අවුට් වී ඇත."])}
    },
    "message": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පණිවිඩය සාර්ථකව යවා ඇත."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබගේ පණිවිඩය යැවීමේ දෝෂයක් ඇති විය."])},
      "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබේ නම ඇතුලත් කරන්න."])},
      "email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කරුණාකර ඔබගේ විද්‍යුත් තැපෑල ඇතුලත් කරන්න."])},
      "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කරුණාකර වලංගු විද්‍යුත් තැපෑලක් ඇතුළු කරන්න."])},
      "message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කරුණාකර පණිවිඩයක් ඇතුළු කරන්න."])},
      "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුර පද ගැලපෙන්නේ නැත."])}
    },
    "login": {
      "region_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කරුණාකර කලාපයක් තෝරාගෙන ඇති බවත් ඔබ වලංගු දුරකථන අංකයක් ඇතුළත් කර ඇති බවත් සහතික කර ගන්න."])},
      "verification_code_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපි ඔබේ අංකයට සත්‍යාපන කේතය යවන තෙක් කරුණාකර රැඳී සිටින්න."])},
      "welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සාර්ථකව පුරනය වී ඇත, නැවත සාදරයෙන් පිළිගනිමු"])},
      "ensure_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කරුණාකර ඔබ වලංගු විද්‍යුත් තැපෑලක් සහ මුරපදයක් ඇතුළත් කර ඇති බවට සහතික වන්න."])},
      "ensure_valid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කරුණාකර ඔබ වලංගු නමක් ඇතුළත් කර ඇති බවට සහතික වන්න."])},
      "signup_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබ සාර්ථකව ලියාපදිංචි වී ඇත."])},
      "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුරපද යළි පිහිටුවීම සාර්ථකව."])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉදිරියට යාමට ඔබගේ මුරපදය ඇතුලත් කරන්න"])}
    },
    "contact": {
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ආදාන ක්ෂේත්‍ර එකක් හෝ කිහිපයක් නිර්ණායක සපුරාලන්නේ නැත."])},
      "email_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබගේ විද්‍යුත් තැපෑල සාර්ථකව යවා ඇත."])}
    },
    "course": {
      "success_enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබ මෙම පාඨමාලාවට සාර්ථකව ලියාපදිංචි වී ඇත."])}
    },
    "feedback": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රතිපෝෂණ සාර්ථකව ඉදිරිපත් කරන ලදී."])}
    },
    "quiz": {
      "first_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබ පළමු ප්‍රශ්නයේ සිටී!"])},
      "fill_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබට ඉදිරියට යාමට පෙර පිළිතුර පිරවිය යුතුය!."])},
      "last_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබ සිටින්නේ අවසාන ප්‍රශ්නයේය, ඔබට ඔබේ පිළිතුරු ඉදිරිපත් කිරීමට අවශ්‍ය බව විශ්වාසද?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රශ්නාවලිය සාර්ථකව ඉදිරිපත් කරන ලදී."])}
    }
  },
  "form": {
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නම"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සම්පූර්ණ නම"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විද්යුත් තැපෑල"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දුරකථන අංකය"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පණිවුඩය"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සමාගම් නාමය"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ජාතිය"])},
      "nationality_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සිංගප්පූරු"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කාර්යභාරය"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුරපදය"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුරපදය තහවුරු කරන්න"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කලාපයේ"])},
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["එක් වරක් සත්‍යාපන කේතය (OTP)"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වයස"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සමූහය"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පෙරනිමිය"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වැඩිහිටි"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සංක්‍රමණිකයා"])}
      },
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ස්ත්රී පුරුෂ භාවය"])},
      "genderOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ලිංගභේදය තෝරන්න"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පිරිමි"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ගැහැණු"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අනික්"])}
      },
      "englishProficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉංග්රීසි ප්රවීණතාව"])},
      "englishProficiencyOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රවීණතාවය තෝරන්න"])},
        "very-poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉතා දුප්පත්"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දුප්පත්"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සාමාන්යය"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යහපත"])},
        "very-good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉතා හොඳයි"])}
      },
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපගේ ස්වේච්ඡා සේවකයෙකු වීමට මිනිත්තු 5ක් ඉතිරිව තිබේද?"])},
      "volunteer_flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපගේ ඩිජිටල් ස්වේච්ඡා සේවකයන් ලෝකයේ ඔවුන්ට අවශ්‍ය ඕනෑම තැනක සිට අපට උපකාර කරයි. අපගේ පද්ධතිය ඔබට දැනුම් දෙන්නේ ඔබ නිදහස්ව සිටින විට පමණක් වන අතර අපගේ සංක්‍රමණික පවුල සඳහා උපකාරක පන්තිවල හෝ නව පන්ති නිර්මාණය කිරීමට ඔබට ඉඩ සලසයි."])}
    },
    "button": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉදිරිපත් කරන්න"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යවන්න"])},
      "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඇතුල් කරන්න"])},
      "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ලියාපදිංචි කරන්න"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යාවත්කාලීන කරන්න"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මකන්න"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දිගටම කරගෙන යන්න"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පුරන්න"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ලියාපදිංචි කරන්න"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යළි පිහිටුවන්න"])},
      "get_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP ලබා ගන්න"])}
    }
  },
  "home": {
    "title": {
      "online_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මාර්ගගත පාඨමාලාව"])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නිදහස්"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සෑම කෙනෙකුටම සහ ඕනෑම කෙනෙකුට මෙවලම් සහ සම්පත් ලබා දීම"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කිසිදු වියදමක් නොමැතිව තමන්ව සවිබල ගන්වන්න."])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["තවත් බලන්න"])},
      "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ආරම්භ කරන්න"])}
    },
    "cards": {
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["බහු භාෂා සහය දක්වයි"])},
      "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබට වර්ධනය වීමට උපකාර වන පාඨමාලා"])},
      "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නොමිලේ පාඨමාලා, සදහටම"])}
    },
    "banner_1": {
      "our_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපේ වැඩ"])},
      "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දක්ෂතාවය"])},
      "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වර්ධනය"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පුහුණුව"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපගේ අරමුණ වන්නේ සිංගප්පූරුවේ ශක්තිමත් භූමිකාවන් භාර ගැනීම සඳහා විදේශීය ශ්‍රමිකයින්ට ඔවුන්ගේ සංජානන කුසලතා වැඩිදියුණු කිරීමට උපකාර කිරීම සහ ඔවුන්ට තමන්ව දියුණු කර ගැනීමට සහ දියුණු වීමට වඩා හොඳ අවස්ථාවක් ලබා දීමයි."])},
      "point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මාර්ගගත ඉගැන්වීම් ඉගෙනීම"])},
      "point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කුසලතා වර්ධනය කිරීම"])},
      "point_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ජීවිත වැඩිදියුණු කිරීම"])}
    },
    "banner_2": {
      "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වර්ධනය කරන්න"])},
      "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබගේ"])},
      "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අනාගතය අප සමඟ!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබේ කුසලතා වැඩි දියුණු කිරීමට ඔබට උපකාර කිරීමට ගැලපෙන පාඨමාලා."])},
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුළු උපදේශකවරු"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලා තිබේ"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විවිධ භාෂා"])}
    },
    "banner_3": {
      "updated_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යාවත්කාලීන පාඨමාලා"])},
      "our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපගේ"])},
      "latest_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නවතම පාඨමාලා"])}
    },
    "banner_4": {
      "taking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ගන්නවා"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අධ්යාපන"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඊළඟට"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["තාක්ෂණය සමඟ මට්ටම"])}
    }
  },
  "v2home": {
    "section1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබට වර්ධනය වීමට උපකාර වන පාඨමාලා"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නොමිලේ මාර්ගගත පාඨමාලාව"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සෑම කෙනෙකුටම සහ ඕනෑම කෙනෙකුට තමන්ව සවිබල ගැන්වීම සඳහා මෙවලම් සහ සම්පත් ලබා දීම"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වියදමකින් තොරව."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලාව ආරම්භ කරන්න"])}
    },
    "section2": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපි ඔබට උදව් කරන්නම්"])},
      "subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දක්ෂතාවය"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වර්ධනය"])},
      "subtitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සහ පුහුණුව"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපගේ අරමුණ වන්නේ සංක්‍රමණික ශ්‍රමිකයන්ගේ නිපුණතා සහ නිපුණතා වැඩි දියුණු කිරීම තුළින් ඔවුන්ගේ ජීවිත නඟා සිටුවීම, එමඟින් වැඩි අවස්ථා සහ ජීවනෝපායන් වැඩිදියුණු කිරීම සඳහා දොරටු විවෘත කිරීමයි. සංක්‍රමණික සේවකයින්ට විශ්වාසයෙන් සහ නිපුණතාවයෙන් යුතුව හැකියාවන්ගෙන් යුත් ලෝකයක සැරිසැරීමට අපි කැපවී සිටිමු."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["තවත් හදාරන්න"])}
    },
    "section3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුළු උපදේශකවරු"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලා තිබේ"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විවිධ භාෂා"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපගේ නවතම පාඨමාලා"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විස්තර බලන්න"])},
      "more_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["තවත් පාඨමාලා"])}
    }
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපි ගැන"])},
    "banner_1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබට වර්ධනය වීමට උපකාර වන පාඨමාලා"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අප සමඟ වර්ධනය වන්න"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growstudio වෙත සාදරයෙන් පිළිගනිමු"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seah Kim Cheok Construction Co. (Pte) Ltd විසින් ආරම්භ කරන ලද Grow Studio යනු විදේශීය ශ්‍රමිකයින්ට ඉගෙනීමට සහ වර්ධනය වීමට සහාය වීම සඳහා කැප වූ නොමිලේ මාර්ගගත වේදිකාවකි. සිංගප්පූරු කළමනාකරණ විශ්ව විද්‍යාලය සහ Superink Pte Ltd ඒකාබද්ධව සහාය දක්වන අතර, විදේශීය ශ්‍රමිකයින්ට ඉංග්‍රීසි, සෞඛ්‍ය සේවා, මූල්‍ය සහ වෙනත් කුසලතා ඉගෙන ගැනීමට උපකාර කිරීම අපගේ අරමුණයි."])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" අපගේ පාඨමාලා ඵලදායි ඉගෙනීම සක්‍රීය කිරීම සඳහා ඇගයීම් සමඟ සම්බන්ධ වී විවිධ භාෂාවලින් ආවරණය කර ඇත. මීට අමතරව, දැනට පවතින වසංගතය, COVID-19 සමඟ, Grow Studio විදේශීය සේවකයන්ට සම්ප්‍රදායික පන්ති කාමර පසුබිමට නිරාවරණය වීමේ අවදානමකින් තොරව නව කුසලතා සහ දැනුම ලබා ගැනීමට ඉඩ සලසයි."])},
      "explore_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලා ගවේෂණය කරන්න"])}
    },
    "banner_2": {
      "desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපව තෝරන්න"])},
      "explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මෙම වෙබ් අඩවියේ සපයා ඇති සෑම පාඩමක්ම සහ පන්තියක්ම නොමිලේ. එය ඕනෑම කෙනෙකුට, විශේෂයෙන්ම විදේශීය ශ්‍රමිකයන්ට විවෘතය."])},
      "desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපේ මෙහෙයුම"])},
      "explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සෑම කෙනෙකුටම සහ ඕනෑම කෙනෙකුට කිසිදු වියදමකින් තොරව තමන්ව සවිබල ගැන්වීම සඳහා මෙවලම් සහ සම්පත් ලබා දෙන්න."])},
      "desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපගේ දැක්ම"])},
      "explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපගේ දේශීය සංක්‍රමණික ප්‍රජාව වර්ධනය කිරීම, ඔවුන්ගේ කුසලතා සහ සන්නිවේදනය වැඩිදියුණු කිරීම."])}
    },
    "banner_3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුළු උපදේශකවරු"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලා තිබේ"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විවිධ භාෂා"])}
    },
    "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපේ කණ්ඩායම"])},
    "banner_4": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["හඳුන්වා දෙමින්"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපගේ කණ්ඩායම හමුවන්න"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපගේ කණ්ඩායම විවිධ පසුබිම් සහ කුසලතා කට්ටල සහිත ප්‍රවීණයන්ගෙන් සමන්විත වන අතර, ඒ සෑම කෙනෙකුම ඔවුන්ගේ අද්විතීය ඉදිරිදර්ශනය මේසයට ගෙන එයි."])},
      "team_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කළමනාකාර අධ්යක්ෂක"])},
      "team_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["තොරතුරු තාක්ෂණ සංවර්ධන අධ්යක්ෂ"])},
      "team_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පරිපාලන අධ්යක්ෂ"])},
      "team_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අධ්‍යාපන අධ්‍යක්ෂ"])},
      "team_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT DevOps නායකයා"])}
    }
  },
  "volunteer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ස්වේච්ඡා සේවකයා"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අදම ස්වේච්ඡා සේවකයෙකු ලෙස ලියාපදිංචි වී අපගේ සංක්‍රමණික සේවකයින්ට ඔවුන්ගේ අධ්‍යාපනයට උපකාර කරන්න"])},
    "form_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මෙම පෝරමය පුරවන්න, අපි ඉක්මනින් ඔබ වෙත පැමිණෙන්නෙමු."])},
    "form_select_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කරුණාකර ඔබට ඉගැන්වීමට පහසු භාෂා තෝරන්න"])}
  },
  "categories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලා"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අප සමඟ වර්ධනය වීමට අපගේ පන්ති ගවේෂණය කරන්න"])},
    "filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලා ගවේෂණය කරන්න"])},
    "filter_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වර්ගය"])},
    "filter_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ටැග්"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලා භාෂාව තෝරන්න"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉගෙනීම ආරම්භ කිරීමට පාඨමාලාවක් තෝරන්න"])},
    "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["තවත් බලන්න"])}
  },
  "courses": {
    "navigation": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සාමාන්ය විස්තර"])},
      "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඩම්"])},
      "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රශ්නාවලිය"])}
    },
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මිල"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නිදහස්"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ලියාපදිංචි කරන්න"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විස්තර බලන්න"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මිනි"])},
    "other_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වෙනත් වීඩියෝ"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ගැන"])},
    "about_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වැඩි විස්තර බැලීමට පාඩමක් තෝරන්න"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සෑම"])}
  },
  "lessons": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දැනට ඉගෙන ගන්නවා"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වීඩියෝ"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රශ්නාවලිය"])},
    "quiz_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රශ්නාවලිය"])},
    "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉදිරිපත් කිරීම"])},
    "presentation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉදිරිපත් කිරීමක් නොමැත"])},
    "presentation_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powerpoint බාගන්න"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපව අමතන්න"])},
    "banner_1": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ස්පර්ශ කරන්න"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["තවත් වර්ධනය වීමට අප අමතන්න"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විද්‍යුත් තැපෑල හරහා අප හා සම්බන්ධ වීමට නිදහස් වන්න"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ලිපිනය"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යවන්න"])}
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔයාගේ නම"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අමතන්න"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විද්යුත් තැපෑල"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පණිවුඩය"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යවන්න"])}
    }
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පැතිකඩ"])},
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පුද්ගලික"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පුද්ගලික තොරතුරු"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නම"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දුරකථන අංකය"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විද්යුත් තැපෑල"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සමූහය"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පෙරනිමිය"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["වැඩිහිටි"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සංක්‍රමණිකයා"])}
      }
    },
    "company": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සමාගම"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සමාගම් විස්තර"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සමාගම් නාමය"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["කාර්යභාරය"])}
    },
    "buttons": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යාවත්කාලීන කරන්න"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මකන්න"])}
    },
    "courses": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලා"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලා ලියාපදිංචි කර ඇත"])},
      "no_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබ කිසිදු පාඨමාලා සඳහා ලියාපදිංචි වී නොමැත"])},
      "course_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලා ප්‍රගතිය"])},
      "quiz_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රශ්න විචාරාත්මක ප්‍රගතිය"])}
    },
    "assessments": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["තක්සේරු කිරීම්"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["තක්සේරු කිරීම් අවසන්"])},
      "no_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඔබ කිසිදු තක්සේරුවක් සම්පූර්ණ කර නැත"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["තත්ත්වය"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ස්කෝර්"])},
      "view_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පිළිතුර බලන්න"])}
    },
    "tutor": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ටියුටර් මෙවලම්"])}
    }
  },
  "login": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පුරන්න"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඊමේල් හෝ දුරකථන අංකය"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුරපදය"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඇතුල් වන්න"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ගිණුමක් නැද්ද?"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ලියාපදිංචි කරන්න"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුරපදය අමතක වුණා ද?"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුරපදය නැවත සකසන්න"])},
      "flavour3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නැතහොත් සමඟ පුරනය වන්න"])},
      "hotline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දුකක් දැනෙනවාද? මෙම ක්ෂණික අංකය අමතන්න: +65 3129 5000"])}
    },
    "register": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ලියාපදිංචි කරන්න"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දුරකථන අංකය"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP ලබා ගන්න"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දැනටමත් ගිණුමක් තිබේද?"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පුරන්න"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ලියාපදිංචි වීමට නොහැකිද?"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මෙතනින් අපව සම්බන්ධ කරගන්න"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මුරපදය නැවත සකසන්න"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විද්යුත් තැපෑල"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යවන්න"])}
    },
    "warning": {
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google පුරනය වීම මේ මොහොතේ නොමැත."])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook පුරනය වීම මේ මොහොතේ නොමැත."])}
    }
  },
  "navigation": {
    "header": {
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMU සහ SuperINK මගින් සහය දක්වයි"])},
      "languages": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉංග්රීසි"])},
        "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["චීන"])},
        "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මැලේ"])},
        "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["දෙමළ"])},
        "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["බුරුම"])},
        "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සිංහල"])},
        "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["බෙංගාලි"])}
      },
      "built_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seah Kim Cheok Construction Co. (Pte) Ltd විසින් ගොඩනගා ඇත"])}
    },
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ගෙදර"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපි ගැන"])},
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පාඨමාලාව"])},
      "shortcourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ප්‍රශ්නාවලිය"])},
      "character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ස්වභාවය"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අමතන්න"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පැතිකඩ"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ලොගින්/ලියාපදිංචි වන්න"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["පිටවීම"])},
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ස්වේච්ඡා සේවකයා"])}
    },
    "footer": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සෑම කෙනෙකුටම සහ ඕනෑම කෙනෙකුට කිසිදු වියදමකින් තොරව තමන්ව සවිබල ගැන්වීම සඳහා මෙවලම් සහ සම්පත් ලබා දීම."])},
      "links": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["සබැඳි"])}
      },
      "contact": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["අපව අමතන්න"])}
      },
      "connect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ඉක්මන් සම්බන්ධතාවය"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["නම"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විද්යුත් තැපෑල"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විස්තර"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["යවන්න"])}
      },
      "flavour": {
        "built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විසින් ගොඩනගා ඇත"])},
        "supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["විසින් සහාය දක්වන ලදී"])}
      }
    }
  },
  "extras": {
    "level1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["මෙම පා course මාලාවේදී, ඔබ ආරම්භ කරන්නේ හෝඩිය, අංක සහ ව්‍යාකරණ වැනි ඉංග්‍රීසි මූලික කරුණු ඉගෙනීමෙනි. ඉන්පසුව, ඔබේ පවුලේ අය සහ මිතුරන් සමඟ සන්නිවේදනය කිරීමට උපකාර වන සරල සංවාද කරන්නේ කෙසේදැයි ඔබ ඉගෙන ගනු ඇත. ආහාර ඇණවුම් කිරීම සහ වෛද්‍යවරයා වෙත යෑම වැනි වඩාත් පොදු අවස්ථා වලදී කතා කරන්නේ කෙසේදැයි ඔබ ඉගෙන ගනු ඇත."])},
    "level2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 මට්ටමේ සිට ඉදිරියට යන විට, ඔබ කාලය, විරාම ලකුණු සහ සංයෝජන වැනි ඉංග්‍රීසි ව්‍යාකරණ පිළිබඳව වඩාත් ගැඹුරින් ඉගෙන ගනු ඇත. ඊට අමතරව, ඔබ ඔබේ හැඟීම් ප්‍රකාශ කිරීම, ඊමේල් ලිවීම සහ විවිධ වැඩ ආශ්‍රිත අවස්ථා වලදී සංවාද කිරීම වැනි පුද්ගලික සහ වැඩ සැකසීම් දෙකෙහිම වැඩි සංවාද කුසලතා ඉගෙන ගනු ඇත."])},
    "level3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 වන මට්ටමට ළඟා වෙමින්, අපි පෙරනිමිති, ආකෘති ක්‍රියා පද වැනි වඩාත් සංකීර්ණ මාතෘකා සමඟ කටයුතු කිරීමට ඉදිරියට යමු, පසුව කියවීම සහ අවබෝධය සඳහා අත්‍යවශ්‍ය කුසලතා ලබා ගැනීමට ඉදිරියට යමු. අවසාන වශයෙන්, අපි ඔබ ඉගෙන ගත් සියල්ල අවසාන සමාලෝචන සැසියකදී අවසන් කර ඔබේ අනාගත ඉංග්‍රීසි අධ්‍යයන සඳහා ඔබට උපකාර කිරීමට යෝජනා ඉදිරිපත් කරන්නෙමු."])}
  }
}