export default {
  "general": {
    "button": {
      "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ketahui Lebih Lanjut"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi Kami"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seterusnya"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sebelumnya"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["belakang"])}
    },
    "languages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inggeris"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cina"])},
      "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melayu"])},
      "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bahasa Tamil"])},
      "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burma"])},
      "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bahasa sinhala"])},
      "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benggali"])}
    }
  },
  "toasts": {
    "logout": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adakah anda pasti mahu log keluar?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah berjaya log keluar."])}
    },
    "message": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesej berjaya dihantar."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terdapat ralat menghantar mesej anda."])},
      "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila masukkan nama anda."])},
      "email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila masukkan e-mel anda."])},
      "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila masukkan e-mel yang sah."])},
      "message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila masukkan mesej."])},
      "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata laluan tidak sepadan."])}
    },
    "login": {
      "region_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila pastikan kawasan dipilih dan anda telah memasukkan nombor telefon yang sah."])},
      "verification_code_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila tunggu sementara kami menghantar kod pengesahan ke nombor anda."])},
      "welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berjaya log masuk, selamat datang kembali"])},
      "ensure_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila pastikan anda telah memasukkan e-mel dan kata laluan yang sah."])},
      "ensure_valid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila pastikan anda telah memasukkan nama yang sah."])},
      "signup_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah berjaya mendaftar."])},
      "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berjaya menetapkan semula kata laluan."])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masukkan kata laluan anda untuk meneruskan"])}
    },
    "contact": {
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Satu atau lebih medan input tidak memenuhi kriteria."])},
      "email_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mel anda telah berjaya dihantar."])}
    },
    "course": {
      "success_enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda telah berjaya mendaftar dalam kursus ini."])}
    },
    "feedback": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maklum balas berjaya dihantar."])}
    },
    "quiz": {
      "first_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda berada pada soalan pertama!"])},
      "fill_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jawapan mesti diisi sebelum anda boleh meneruskan!."])},
      "last_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda berada pada soalan terakhir, adakah anda pasti mahu menyerahkan jawapan anda?"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuiz berjaya dihantar."])}
    }
  },
  "form": {
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama penuh"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mel"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombor telefon"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesej"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nama syarikat"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kewarganegaraan"])},
      "nationality_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["warga Singapura"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peranan"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata laluan"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sahkan Kata Laluan"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wilayah"])},
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod Pengesahan Satu Kali (OTP)"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umur"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumpulan"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lalai"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warga emas"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migran"])}
      },
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jantina"])},
      "genderOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih jantina"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jantan"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["perempuan"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lain-lain"])}
      },
      "englishProficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penguasaan Bahasa Inggeris"])},
      "englishProficiencyOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kemahiran"])},
        "very-poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sangat miskin"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["miskin"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purata"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baik"])},
        "very-good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sangat bagus"])}
      },
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ada 5 minit lagi untuk menjadi sukarelawan kami?"])},
      "volunteer_flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukarelawan digital kami membantu kami dari mana sahaja mereka mahu di Dunia. Sistem kami hanya memberitahu anda apabila anda bebas dan membenarkan anda meluangkan masa lapang anda dalam tunjuk ajar atau membuat kelas baharu untuk keluarga migran kami."])}
    },
    "button": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantar"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantar"])},
      "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masuk"])},
      "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemas kini"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padam"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teruskan"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Masuk"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tetapkan semula"])},
      "get_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapatkan OTP"])}
    }
  },
  "home": {
    "title": {
      "online_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus Dalam Talian"])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percuma"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyediakan semua orang dan sesiapa sahaja dengan alatan dan sumber untuk"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["memperkasakan diri mereka tanpa kos."])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Lagi"])},
      "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mulakan"])}
    },
    "cards": {
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pelbagai Bahasa Disokong"])},
      "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus untuk membantu anda berkembang"])},
      "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus percuma, selamanya"])}
    },
    "banner_1": {
      "our_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kerja Kami"])},
      "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemahiran"])},
      "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembangunan"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latihan"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matlamat kami adalah untuk membantu pekerja asing meningkatkan kemahiran kognitif mereka untuk mengambil peranan yang lebih kuat di Singapura dan juga memberi mereka peluang yang lebih baik untuk memperbaiki dan memajukan diri mereka."])},
      "point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembelajaran Pengajaran Dalam Talian"])},
      "point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membangunkan Kemahiran"])},
      "point_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memperbaiki Kehidupan"])}
    },
    "banner_2": {
      "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tumbuh"])},
      "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["awak"])},
      "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["masa depan bersama kami!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus yang disesuaikan untuk membantu anda meningkatkan kemahiran anda."])},
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah Tenaga Pengajar"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus Tersedia"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbezaan bahasa"])}
    },
    "banner_3": {
      "updated_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus yang dikemas kini"])},
      "our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kami"])},
      "latest_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus Terkini"])}
    },
    "banner_4": {
      "taking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mengambil"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendidikan"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ke Seterusnya"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tahap dengan Teknologi"])}
    }
  },
  "v2home": {
    "section1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus untuk membantu anda berkembang"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus Dalam Talian Percuma"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyediakan semua orang dan sesiapa sahaja alat dan sumber untuk memperkasakan diri mereka sendiri"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tanpa sebarang kos."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MULAKAN KURSUS"])}
    },
    "section2": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kami akan membantu anda"])},
      "subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemahiran"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pembangunan"])},
      "subtitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dan Latihan"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matlamat kami adalah untuk meningkatkan kehidupan pekerja asing dengan meningkatkan kemahiran dan kecekapan mereka, sekali gus membuka pintu kepada peningkatan peluang dan kehidupan yang lebih baik. Kami komited untuk membolehkan pekerja asing mengharungi dunia kemungkinan dengan yakin dan cekap."])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KETAHUI LEBIH LANJUT"])}
    },
    "section3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah Tenaga Pengajar"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus Tersedia"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbezaan bahasa"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus Terkini Kami"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LIHAT BUTIRAN"])},
      "more_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEBIH BANYAK KURSUS"])}
    }
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang kita"])},
    "banner_1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus untuk membantu anda berkembang"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berkembang Bersama Kami"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selamat datang ke Growstudio"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimulakan oleh Seah Kim Cheok Construction Co. (Pte) Ltd, Grow Studio ialah platform dalam talian percuma yang didedikasikan untuk menyokong pekerja asing belajar dan berkembang. Disokong bersama oleh Singapore Management University dan Superink Pte Ltd, kami berhasrat untuk membantu pekerja asing mempelajari bahasa Inggeris, Penjagaan Kesihatan, Kewangan dan kemahiran lain supaya mereka boleh meningkatkan kerjaya dan gaya hidup mereka."])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Kursus kami diliputi dalam pelbagai bahasa ditambah dengan penilaian untuk membolehkan pembelajaran berkesan. Selain itu, dengan pandemik yang berterusan, COVID-19, Grow Studio membolehkan pekerja asing memperoleh kemahiran dan pengetahuan baharu tanpa perlu mengambil risiko terdedah kepada persekitaran bilik darjah tradisional."])},
      "explore_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXPORE KURSUS"])}
    },
    "banner_2": {
      "desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Kami"])},
      "explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setiap pelajaran dan kelas yang disediakan di laman web ini adalah percuma. Ia terbuka kepada sesiapa sahaja terutama pekerja asing."])},
      "desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misi kita"])},
      "explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sediakan semua orang dan sesiapa sahaja alat dan sumber untuk memperkasakan diri mereka tanpa sebarang kos."])},
      "desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penglihatan Kami"])},
      "explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Untuk mengembangkan komuniti migran tempatan kita, meningkatkan kemahiran dan komunikasi mereka."])}
    },
    "banner_3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jumlah Pengajar"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus Tersedia"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perbezaan bahasa"])}
    },
    "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasukan kami"])},
    "banner_4": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memperkenalkan"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temui Pasukan Kami"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasukan kami terdiri daripada pakar dengan pelbagai latar belakang dan set kemahiran, masing-masing membawa perspektif unik mereka ke meja."])},
      "team_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengarah Urusan"])},
      "team_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengarah Pembangunan IT"])},
      "team_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengarah Pentadbiran"])},
      "team_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengarah Pendidikan"])},
      "team_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peneraju IT DevOps"])}
    }
  },
  "volunteer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukarelawan"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar sebagai sukarelawan hari ini dan bantu pekerja asing kami dengan pendidikan mereka"])},
    "form_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isi borang ini dan kami akan menghubungi anda semula sebentar lagi."])},
    "form_select_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila pilih bahasa yang anda selesa mengajar"])}
  },
  "categories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terokai kelas kami untuk berkembang bersama kami"])},
    "filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terokai Kursus"])},
    "filter_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kategori"])},
    "filter_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Bahasa Kursus"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih kursus untuk mula belajar"])},
    "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat lagi"])}
  },
  "courses": {
    "navigation": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butiran Am"])},
      "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pengajaran"])},
      "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuiz"])}
    },
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["harga"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Percuma"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DAFTAR"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat butiran"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
    "other_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["video lain"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang"])},
    "about_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih pelajaran untuk melihat butiran lanjut"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semua"])}
  },
  "lessons": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sedang Belajar"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuiz"])},
    "quiz_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuiz"])},
    "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persembahan"])},
    "presentation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiada pembentangan tersedia"])},
    "presentation_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muat turun Powerpoint"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi Kami"])},
    "banner_1": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi kami untuk berkembang lebih banyak"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sila hubungi kami melalui e-mel"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alamat"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantar"])}
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama awak"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenalan"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mel"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesej"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HANTAR"])}
    }
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peribadi"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maklumat peribadi"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombor telefon"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mel"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumpulan"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lalai"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warga emas"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migran"])}
      }
    },
    "company": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syarikat"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Butiran Syarikat"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nama syarikat"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peranan"])}
    },
    "buttons": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemas kini"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padam"])}
    },
    "courses": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus Didaftarkan"])},
      "no_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda tidak mendaftar dalam mana-mana kursus"])},
      "course_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemajuan Kursus"])},
      "quiz_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kemajuan Kuiz"])}
    },
    "assessments": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penilaian Selesai"])},
      "no_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anda belum menyelesaikan sebarang penilaian"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skor"])},
      "view_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lihat Jawapan"])}
    },
    "tutor": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alat Tutor"])}
    }
  },
  "login": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Masuk"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mel atau Nombor Telefon"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kata laluan"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log masuk"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiada akaun?"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lupa kata laluan?"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menetapkan semula kata laluan"])},
      "flavour3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atau log masuk dengan"])},
      "hotline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rasa sedih? Hubungi Talian Utama Ini: +65 3129 5000"])}
    },
    "register": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daftar"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombor telefon"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dapatkan OTP"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sudah mempunyai akaun?"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Masuk"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidak dapat mendaftar?"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hubungi kami di sini"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menetapkan semula kata laluan"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mel"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantar"])}
    },
    "warning": {
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Masuk Google tidak tersedia pada masa ini."])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Masuk Facebook tidak tersedia pada masa ini."])}
    }
  },
  "navigation": {
    "header": {
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disokong oleh SMU dan SuperINK"])},
      "languages": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inggeris"])},
        "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cina"])},
        "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melayu"])},
        "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bahasa Tamil"])},
        "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burma"])},
        "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bahasa sinhala"])},
        "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benggali"])}
      },
      "built_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibina oleh Seah Kim Cheok Construction Co. (Pte) Ltd"])}
    },
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumah"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tentang kita"])},
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursus"])},
      "shortcourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuiz"])},
      "character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perwatakan"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kenalan"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOG MASUK MENDAFTAR"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOG KELUAR"])},
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukarelawan"])}
    },
    "footer": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menyediakan semua orang dan sesiapa sahaja alat dan sumber untuk memperkasakan diri mereka tanpa sebarang kos."])},
      "links": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAUTAN"])}
      },
      "contact": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUBUNGI KAMI"])}
      },
      "connect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAMBUNG CEPAT"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nama"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mel"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penerangan"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hantar"])}
      },
      "flavour": {
        "built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dibina oleh"])},
        "supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disokong oleh"])}
      }
    }
  },
  "extras": {
    "level1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dalam kursus ini, anda akan bermula dengan mempelajari asas bahasa Inggeris, seperti abjad, nombor dan tatabahasa. Selepas itu, anda akan belajar cara membuat perbualan mudah yang akan membantu anda berkomunikasi dengan keluarga dan rakan anda. Anda juga akan belajar cara bercakap dalam senario yang lebih biasa seperti memesan makanan dan pergi ke doktor."])},
    "level2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berterusan dari tahap 1, anda akan mempelajari lebih mendalam tentang tatabahasa Inggeris seperti kala, tanda baca dan kata hubung. Di samping itu, anda akan mempelajari lebih banyak kemahiran perbualan dalam tetapan peribadi dan kerja, seperti meluahkan perasaan anda, menulis e-mel dan berbual dalam pelbagai senario berkaitan kerja."])},
    "level3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mencapai tahap 3, kami bergerak ke dalam menangani topik yang lebih rumit seperti preposisi, kata kerja modal, kemudian maju ke dalam mengambil kemahiran penting untuk membaca dan memahami. Akhir sekali, kami akan menyimpulkan semua yang telah anda pelajari dalam sesi semakan akhir dan menawarkan cadangan untuk membantu anda dalam pengajian bahasa Inggeris masa hadapan anda."])}
  }
}