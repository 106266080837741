export default {
  "general": {
    "button": {
      "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以前的"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后退"])}
    },
    "languages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国人"])},
      "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["马来语"])},
      "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰米尔语"])},
      "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缅甸语"])},
      "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僧伽罗语"])},
      "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["孟加拉"])}
    }
  },
  "toasts": {
    "logout": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要退出吗？"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功退出。"])}
    },
    "message": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息已成功发送。"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送您的消息时出错。"])},
      "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入你的名字。"])},
      "email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的电子邮件。"])},
      "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入有效电子邮件。"])},
      "message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入留言。"])},
      "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不匹配。"])}
    },
    "login": {
      "region_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保选择了一个区域并且您输入了有效的电话号码。"])},
      "verification_code_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们正在将验证码发送到您的号码，请稍候。"])},
      "welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录成功，欢迎回来"])},
      "ensure_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保您输入了有效的电子邮件和密码。"])},
      "ensure_valid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确保您输入了有效的名称。"])},
      "signup_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功注册。"])},
      "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码重置成功。"])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入您的密码以继续"])}
    },
    "contact": {
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一个或多个输入字段不满足条件。"])},
      "email_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的电子邮件已经发送成功。"])}
    },
    "course": {
      "success_enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功注册此课程。"])}
    },
    "feedback": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反馈提交成功。"])}
    },
    "quiz": {
      "first_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你说的是第一个问题！"])},
      "fill_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必须先填写答案才能继续！。"])},
      "last_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您正在回答最后一个问题，您确定要提交答案吗？"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验提交成功。"])}
    }
  },
  "form": {
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全名"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名称"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国籍"])},
      "nationality_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新加坡人"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地区"])},
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次性验证码 (OTP)"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年龄"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团体"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["老年"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移民"])}
      },
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性别"])},
      "genderOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择性别"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男性"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["女性"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])}
      },
      "englishProficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语水平"])},
      "englishProficiencyOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择熟练程度"])},
        "very-poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["很差"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贫穷的"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均的"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好的"])},
        "very-good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非常好"])}
      },
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有 5 分钟时间成为我们的志愿者吗？"])},
      "volunteer_flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的数字志愿者在世界任何地方为我们提供帮助。我们的系统只会在您有空时通知您，让您利用业余时间为我们的移民家庭辅导或创建新课程。"])}
    },
    "button": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])},
      "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进入"])},
      "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
      "get_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取一次性密码"])}
    }
  },
  "home": {
    "title": {
      "online_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在线课程"])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自由的"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为每个人和任何人提供工具和资源"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不花任何代价就可以增强自己的能力。"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
      "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始使用"])}
    },
    "cards": {
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持多语言"])},
      "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助你成长的课程"])},
      "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永久免费课程"])}
    },
    "banner_1": {
      "our_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的作品"])},
      "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技能"])},
      "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发展"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["训练"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的目标是帮助外籍工人提高认知技能，以便在新加坡发挥更重要的作用，并为他们提供更好的机会来提高和发展自己。"])},
      "point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在线教学学习"])},
      "point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发展技能"])},
      "point_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["改善生活"])}
    },
    "banner_2": {
      "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生长"])},
      "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的"])},
      "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未来与我们同在！"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["量身定制的课程可帮助您提高技能。"])},
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["讲师总数"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选课程"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不同的语言"])}
    },
    "banner_3": {
      "updated_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新课程"])},
      "our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的"])},
      "latest_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新课程"])}
    },
    "banner_4": {
      "taking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服用"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教育"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到下一个"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与技术水平"])}
    }
  },
  "v2home": {
    "section1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助你成长的课程"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费在线课程"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为每个人和任何人提供增强自我能力的工具和资源"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费。"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始课程"])}
    },
    "section2": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们会帮助您"])},
      "subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技能"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发展"])},
      "subtitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["及培训"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的目标是通过提高移民工人的技能和能力来改善他们的生活，从而为增加机会和改善生计打开大门。我们致力于让移民工人充满信心和能力地驾驭充满可能性的世界。"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["了解更多"])}
    },
    "section3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["讲师总数"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选课程"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不同的语言"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的最新课程"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看具体信息"])},
      "more_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多课程"])}
    }
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
    "banner_1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助你成长的课程"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与我们一起成长"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎来到成长工作室"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grow Studio 由 Seah Kim Cheok Construction Co. (Pte) Ltd 发起，是一个免费的在线平台，致力于支持外籍工人学习和成长。我们由新加坡管理大学和 Superink Pte Ltd 联合支持，旨在帮助外籍工人学习英语、医疗保健、金融和其他技能，从而改善他们的职业和生活方式。"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 我们的课程涵盖多种语言，并配有评估，以实现有效的学习。此外，随着新冠肺炎 (COVID-19) 疫情的持续蔓延，Grow Studio 允许外籍工人获得新的技能和知识，而不必冒接触传统课堂环境的风险。"])},
      "explore_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索课程"])}
    },
    "banner_2": {
      "desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择我们"])},
      "explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本网站提供的每节课和课程都是免费的。它对任何人开放，尤其是外国工人。"])},
      "desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的任务"])},
      "explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费为每个人和任何人提供工具和资源来增强自己的能力。"])},
      "desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的愿景"])},
      "explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发展我们当地的移民社区，提高他们的技能和沟通能力。"])}
    },
    "banner_3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["讲师总数"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选课程"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不同的语言"])}
    },
    "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的队伍"])},
    "banner_4": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["介绍"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认识我们的团队"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们的团队由具有不同背景和技能的专家组成，每个人都带来了自己独特的视角。"])},
      "team_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常务董事"])},
      "team_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT开发总监"])},
      "team_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行政总监"])},
      "team_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教育总监"])},
      "team_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT 开发运营负责人"])}
    }
  },
  "volunteer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["志愿者"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即注册成为志愿者，帮助我们的农民工接受教育"])},
    "form_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["填写此表格，我们会尽快回复您。"])},
    "form_select_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择您适合教授的语言"])}
  },
  "categories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培训班"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索我们的课程，与我们一起成长"])},
    "filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探索课程"])},
    "filter_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
    "filter_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标签"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择课程语言"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择课程开始学习"])},
    "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])}
  },
  "courses": {
    "navigation": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一般详情"])},
      "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["教训"])},
      "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])}
    },
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自由的"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看具体信息"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分钟"])},
    "other_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他视频"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于"])},
    "about_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择课程以查看更多详细信息"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])}
  },
  "lessons": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前正在研究"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["视频"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
    "quiz_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
    "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推介会"])},
    "presentation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有可用的演示文稿"])},
    "presentation_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载幻灯片"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])},
    "banner_1": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保持联系"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们以获取更多发展"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请随时通过电子邮件联系我们"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])}
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的名字"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接触"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])}
    }
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轮廓"])},
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人的"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人资料"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团体"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["老年"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移民"])}
      }
    },
    "company": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司详情"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司名称"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])}
    },
    "buttons": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])}
    },
    "courses": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培训班"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已注册课程"])},
      "no_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您尚未注册任何课程"])},
      "course_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["课程进度"])},
      "quiz_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验进度"])}
    },
    "assessments": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评估"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评估已完成"])},
      "no_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您还没有完成任何评估"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地位"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分数"])},
      "view_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看答案"])}
    },
    "tutor": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导师工具"])}
    }
  },
  "login": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件或电话号码"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有帐户？"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码？"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重设密码"])},
      "flavour3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或者登录"])},
      "hotline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["心情失落？拨打此热线：+65 3129 5000"])}
    },
    "register": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取一次性密码"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经有帐户？"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登入"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法注册？"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在这里联系我们"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重设密码"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])}
    },
    "warning": {
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google 登录目前不可用。"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook 登录目前不可用。"])}
    }
  },
  "navigation": {
    "header": {
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由 SMU 和 SuperINK 支持"])},
      "languages": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英语"])},
        "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中国人"])},
        "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["马来语"])},
        "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泰米尔语"])},
        "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缅甸语"])},
        "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僧伽罗语"])},
        "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["孟加拉"])}
      },
      "built_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由 Seah Kim Cheok Construction Co (Pte) Ltd 建造"])}
    },
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于我们"])},
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["课程"])},
      "shortcourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["测验"])},
      "character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特点"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接触"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["轮廓"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登陆注册"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["志愿者"])}
    },
    "footer": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费为每个人和任何人提供工具和资源来增强自己的能力。"])},
      "links": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接"])}
      },
      "contact": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系我们"])}
      },
      "connect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速连接"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])}
      },
      "flavour": {
        "built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由__建造"])},
        "supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持者"])}
      }
    }
  },
  "extras": {
    "level1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在本课程中，您将从学习英语基础知识开始，例如字母、数字和语法。接下来，您将学习如何进行简单的对话，这将有助于您与家人和朋友沟通。您还将学习如何在更常见的场景中进行交谈，例如点餐和去看医生。"])},
    "level2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从第一级继续，您将更深入地学习英语语法，例如时态、标点符号和连词。此外，您还将在个人和工作环境中学习更多对话技巧，例如表达您的感受、撰写电子邮件以及在各种与工作相关的场景中进行交谈。"])},
    "level3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["达到第 3 级，我们将开始处理更复杂的主题，例如介词、情态动词，然后进一步学习阅读和理解的基本技能。最后，我们将在期末复习中总结您所学的内容，并提出建议，以帮助您未来的英语学习。"])}
  }
}