export default {
  "general": {
    "button": {
      "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပိုမိုသိရှိရန်"])},
      "contact_us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြှနျုပျတို့ကိုဆကျသှယျရနျ"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နောက်တစ်ခု"])},
      "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အရင်"])},
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျော"])}
    },
    "languages": {
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အင်္ဂလိပ်စာ"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တရုတ်"])},
      "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မလေး"])},
      "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တမီလ်"])},
      "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မြန်မာ"])},
      "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆင်ဟာလီ"])},
      "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဘင်္ဂါလီ"])}
    }
  },
  "toasts": {
    "logout": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထွက်လိုသည်မှာ သေချာပါသလား။"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်သည် အောင်မြင်စွာ ထွက်ပြီးပါပြီ။"])}
    },
    "message": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မက်ဆေ့ချ်ကို အောင်မြင်စွာ ပေးပို့ခဲ့သည်။"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင့်မက်ဆေ့ဂျ်ပို့ရာတွင် အမှားအယွင်းရှိနေသည်။"])},
      "name_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင့်အမည်ကို ထည့်သွင်းပါ။"])},
      "email_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင့်အီးမေးလ်ကို ထည့်ပါ။"])},
      "email_invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျေးဇူးပြု၍ တရားဝင်အီးမေးလ်ကို ထည့်ပါ။"])},
      "message_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျေးဇူးပြု၍ မက်ဆေ့ချ်တစ်ခု ထည့်ပါ။"])},
      "password_mismatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စကားဝှက်များ မတိုက်ဆိုင်ပါ။"])}
    },
    "login": {
      "region_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျေးဇူးပြု၍ ဒေသတစ်ခုကို ရွေးချယ်ပြီး မှန်ကန်သောဖုန်းနံပါတ်တစ်ခု ထည့်သွင်းထားကြောင်း သေချာပါစေ။"])},
      "verification_code_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့သည် သင်၏နံပါတ်သို့ အတည်ပြုကုဒ်ကို ပေးပို့စဉ် စောင့်ပါ။"])},
      "welcome_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်ဝင်ပြီးပြီ၊ ပြန်လာတာကို ကြိုဆိုပါတယ်။"])},
      "ensure_valid_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင့်တွင် မှန်ကန်သော အီးမေးလ်နှင့် စကားဝှက်ကို ထည့်သွင်းထားကြောင်း သေချာပါစေ။"])},
      "ensure_valid_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင့်တွင်မှန်ကန်သောအမည်တစ်ခုထည့်သွင်းထားကြောင်းသေချာပါစေ။"])},
      "signup_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်သည် အောင်မြင်စွာ စာရင်းသွင်းပြီးပါပြီ။"])},
      "password_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စကားဝှက်ကို အောင်မြင်စွာ ပြင်ဆင်သတ်မှတ်ပါ။"])},
      "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရှေ့ဆက်ရန် သင့်စကားဝှက်ကို ထည့်ပါ။"])}
    },
    "contact": {
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ခု သို့မဟုတ် တစ်ခုထက်ပိုသော ထည့်သွင်းမှုအကွက်များသည် စံနှုန်းများကို မဖြည့်ဆည်းပေးပေ။"])},
      "email_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင့်အီးမေးလ်ကို အောင်မြင်စွာ ပို့ပြီးပါပြီ။"])}
    },
    "course": {
      "success_enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဒီသင်တန်းကို အောင်မြင်စွာ စာရင်းသွင်းပြီးပါပြီ။"])}
    },
    "feedback": {
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြံပြုချက်ကို အောင်မြင်စွာ တင်သွင်းခဲ့သည်။"])}
    },
    "quiz": {
      "first_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မင်းက ပထမမေးခွန်းပါ။"])},
      "fill_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်ဆက်လက်မလုပ်ဆောင်မီ အဖြေဖြည့်ရပါမည်။"])},
      "last_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်သည် နောက်ဆုံးမေးခွန်းတွင် ရှိနေသည်၊ သင်၏အဖြေများကို တင်ပြလိုသည်မှာ သေချာပါသလား။"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပဟေဠိကို အောင်မြင်စွာ တင်သွင်းခဲ့သည်။"])}
    }
  },
  "form": {
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နာမည်"])},
      "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နာမည်အပြည့်အစုံ"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အီးမေးလ်"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖုန်းနံပါတ်"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မက်ဆေ့ချ်"])},
      "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကုမ္ပဏီအမည်"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူမျိုးစု"])},
      "nationality_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စင်ကာပူနိုင်ငံသား"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခန်းကဏ္ဍ"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စကားဝှက်"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စကားဝှက်အတည်ပြုခြင်း"])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တိုင်းဒေသကြီး"])},
      "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တစ်ကြိမ်အတည်ပြုကုဒ် (OTP)"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသက်"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အဖွဲ့"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပုံသေ"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သက်ကြီးရွယ်အို"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရွှေ့ပြောင်း"])}
      },
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျား၊"])},
      "genderOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender ကို ရွေးပါ။"])},
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အထီး"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အပျို"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တခြား"])}
      },
      "englishProficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အင်္ဂလိပ်စာကျွမ်းကျင်မှု"])},
      "englishProficiencyOptions": {
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွမ်းကျင်မှုကို ရွေးချယ်ပါ။"])},
        "very-poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အရမ်းညံ့တယ်။"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆင်းရဲတယ်။"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပျမ်းမျှ"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကောင်းတယ်။"])},
        "very-good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အလွန်ကောင်းသည်"])}
      },
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့၏ စေတနာ့ဝန်ထမ်းလုပ်ရန် ၅ မိနစ်သာ အချိန်ရှိပါသလား။"])},
      "volunteer_flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့၏ ဒစ်ဂျစ်တယ်စေတနာ့ဝန်ထမ်းများသည် ကမ္ဘာပေါ်ရှိ မည်သည့်နေရာမှမဆို ကျွန်ုပ်တို့အား ကူညီဆောင်ရွက်ပေးပါသည်။ ကျွန်ုပ်တို့၏စနစ်သည် သင်အားလပ်ချိန်တွင်သာ သင့်အား အသိပေးပြီး ကျွန်ုပ်တို့၏ရွှေ့ပြောင်းမိသားစုအတွက် ကျူရှင်သင်ကြားခြင်း သို့မဟုတ် အတန်းသစ်များဖန်တီးခြင်းတွင် သင့်အားလပ်ချိန်များကို ကုန်ဆုံးခွင့်ပေးပါသည်။"])}
    },
    "button": {
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တင်ပြပါ။"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပို့ပါ။"])},
      "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဝင်ပါ။"])},
      "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာရင်းသွင်းပါ။"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အပ်ဒိတ်"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖျက်ပါ။"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆက်လက်"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆိုင်းအင်လုပ်ခြင်း"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်ပုံတင်ပါ။"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြန်လည်သတ်မှတ်ပါ။"])},
      "get_otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP ရယူပါ။"])}
    }
  },
  "home": {
    "title": {
      "online_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အွန်လိုင်းသင်တန်း"])},
      "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခမဲ့"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူတိုင်းနှင့် မည်သူမဆို ကိရိယာများနှင့် အရင်းအမြစ်များကို ပံ့ပိုးပေးသည်။"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခကြေးငွေမယူဘဲ သူတို့ကိုယ်သူတို့ ခွန်အားပေးပါ။"])},
      "view_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပိုမိုကြည့်ရှုပါ။"])},
      "get_started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စတင်လိုက်ပါ။"])}
    },
    "cards": {
      "left": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဘာသာစကားပေါင်းစုံကို ပံ့ပိုးထားသည်။"])},
      "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြီးပွားတိုးတက်အောင် ကူညီပေးမည့် သင်တန်းများ"])},
      "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခမဲ့သင်တန်းများ၊ ထာဝရ"])}
    },
    "banner_1": {
      "our_works": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့၏အလုပ်များ"])},
      "skill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွမ်းကျင်မှု"])},
      "development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖွံ့ဖြိုးတိုးတက်ရေး"])},
      "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လေ့ကျင့်ရေး"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့၏ ရည်ရွယ်ချက်မှာ စင်ကာပူတွင် ပိုမိုအားကောင်းသည့် အခန်းကဏ္ဍများတွင် ပါဝင်လာစေရန် နိုင်ငံခြားအလုပ်သမားများ၏ သိမြင်မှုစွမ်းရည်များ တိုးတက်ကောင်းမွန်လာစေရန်နှင့် ၎င်းတို့ကိုယ်တိုင် တိုးတက်ရန်နှင့် ဖွံ့ဖြိုးတိုးတက်ရန် ပိုမိုကောင်းမွန်သော အခွင့်အလမ်းများပေးရန်ဖြစ်သည်။"])},
      "point_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Teaching Learning ၊"])},
      "point_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အရည်အချင်းများ ဖွံ့ဖြိုးတိုးတက်ရေး"])},
      "point_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဘဝတိုးတက်ရေး"])}
    },
    "banner_2": {
      "grow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြီးပွား"])},
      "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်၏"])},
      "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ငါတို့နှင့်အတူအနာဂတ်!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင့်အရည်အချင်းများကို တိုးတက်စေရန်အတွက် အံဝင်ခွင်ကျရှိသော သင်တန်းများ။"])},
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စုစုပေါင်း သင်တန်းပို့ချသူများ"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်တန်းများ ရရှိနိုင်ပါပြီ။"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဘာသာစကားအမျိုးမျိုး"])}
    },
    "banner_3": {
      "updated_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အပ်ဒိတ်သင်တန်းများ"])},
      "our": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်တော်တို့ရဲ့"])},
      "latest_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နောက်ဆုံးရသင်တန်းများ"])}
    },
    "banner_4": {
      "taking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ယူခြင်း။"])},
      "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပညာရေး"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နောက်တစ်ခု"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နည်းပညာဖြင့် အဆင့်လိုက်"])}
    }
  },
  "v2home": {
    "section1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြီးပွားတိုးတက်အောင် ကူညီပေးမည့် သင်တန်းများ"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခမဲ့အွန်လိုင်းသင်တန်း"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူတိုင်းနှင့် မည်သူမဆို ၎င်းတို့ကိုယ်ကို ခွန်အားဖြစ်စေရန် ကိရိယာများနှင့် အရင်းအမြစ်များကို ပံ့ပိုးပေးသည်။"])},
      "description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကုန်ကျစရိတ်မရှိပါ။"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်တန်းစပါ။"])}
    },
    "section2": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့သည် သင့်အား ကူညီပါမည်။"])},
      "subtitle_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွမ်းကျင်မှု"])},
      "subtitle_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖွံ့ဖြိုးတိုးတက်ရေး"])},
      "subtitle_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပြီးတော့ Training"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့၏ ရည်ရွယ်ချက်မှာ ရွှေ့ပြောင်းအလုပ်သမားများ၏ ကျွမ်းကျင်မှုနှင့် အရည်အချင်းများကို မြှင့်တင်ပေးခြင်းဖြင့် အခွင့်အလမ်းများ တိုးမြင့်လာစေရန်နှင့် အသက်မွေးဝမ်းကြောင်းများ တိုးမြင့်လာစေရန် တံခါးဖွင့်ပေးရန်အတွက် ဖြစ်ပါသည်။ ကျွန်ုပ်တို့သည် ယုံကြည်စိတ်ချမှုနှင့် ကျွမ်းကျင်မှုဖြင့် ဖြစ်နိုင်ခြေရှိသော ကမ္ဘာကို သွားလာနိုင်ရန် ရွှေ့ပြောင်းအလုပ်သမားများကို လုပ်ဆောင်ရန် ကတိပြုပါသည်။"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပိုမိုသိရှိရန်"])}
    },
    "section3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စုစုပေါင်း သင်တန်းပို့ချသူများ"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်တန်းများ ရရှိနိုင်ပါပြီ။"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မတူညီသောဘာသာစကားများ"])}
    },
    "section4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့၏နောက်ဆုံးပေါ်သင်တန်းများ"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသေးစိတ်ကို ကြည့်ပါ။"])},
      "more_courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နောက်ထပ် သင်တန်းများ"])}
    }
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြှနျုပျတို့အကွောငျး"])},
    "banner_1": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြီးပွားတိုးတက်အောင် ကူညီပေးမည့် သင်တန်းများ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့နှင့်အတူ ကြီးပွားပါ။"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Growstudio မှလှိုက်လှဲစွာကြိုဆိုပါသည်။"])},
      "description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seah Kim Cheok Construction Co. (Pte) Ltd မှ အစပြုထားသည့် Grow Studio သည် နိုင်ငံခြားအလုပ်သမားများ သင်ယူရန်နှင့် ကြီးထွားလာစေရန် ပံ့ပိုးပေးသည့် အခမဲ့အွန်လိုင်းပလက်ဖောင်းတစ်ခုဖြစ်သည်။ Singapore Management University နှင့် Superink Pte Ltd တို့၏ ပူးပေါင်းပံ့ပိုးမှုဖြင့် နိုင်ငံခြားအလုပ်သမားများသည် ၎င်းတို့၏ အသက်မွေးဝမ်းကြောင်းနှင့် လူနေမှုပုံစံကို တိုးတက်စေရန်အတွက် အင်္ဂလိပ်၊ ကျန်းမာရေးစောင့်ရှောက်မှု၊ ဘဏ္ဍာရေးနှင့် အခြားကျွမ်းကျင်မှုများကို တတ်မြောက်စေရန် ကူညီပေးနိုင်ရန် ရည်ရွယ်ပါသည်။"])},
      "description_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" ကျွန်ုပ်တို့၏သင်တန်းများသည် ထိရောက်သောသင်ယူမှုကိုဖြစ်စေရန်အတွက် အကဲဖြတ်မှုများနှင့်အတူ ဘာသာစကားအမျိုးမျိုးဖြင့် အကျုံးဝင်ပါသည်။ ထို့အပြင် လက်ရှိဖြစ်ပွားနေသော ကပ်ရောဂါ၊ COVID-19 နှင့်အတူ၊ Grow Studio သည် နိုင်ငံခြားအလုပ်သမားများအား ရိုးရာစာသင်ခန်းများကို စွန့်စားစရာမလိုဘဲ ကျွမ်းကျင်မှုနှင့် ဗဟုသုတအသစ်များ ရရှိစေမည်ဖြစ်သည်။"])},
      "explore_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXORE သင်တန်းများ"])}
    },
    "banner_2": {
      "desc_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့ကို ရွေးချယ်ပါ။"])},
      "explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဤ site တွင်ပေးထားသောသင်ခန်းစာနှင့်အတန်းတိုင်းသည်အခမဲ့ဖြစ်သည်။ အထူးသဖြင့် နိုင်ငံခြားသား အလုပ်သမားများကို မည်သူမဆို ဖွင့်လှစ်ထားသည်။"])},
      "desc_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့၏သာသနာ"])},
      "explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူတိုင်းနှင့် မည်သူမဆို ၎င်းတို့ကို အခကြေးငွေမယူဘဲ ခွန်အားဖြစ်စေရန်အတွက် ကိရိယာများနှင့် အရင်းအမြစ်များကို ပေးဆောင်ပါ။"])},
      "desc_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့၏အမြင်များ"])},
      "explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့၏ဒေသခံ ရွှေ့ပြောင်းအသိုက်အဝန်း ကြီးထွားလာစေရန်၊ ၎င်းတို့၏ ကျွမ်းကျင်မှုနှင့် ဆက်သွယ်ရေးကို တိုးတက်စေပါသည်။"])}
    },
    "banner_3": {
      "total_instructors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စုစုပေါင်း သင်တန်းပို့ချသူများ"])},
      "courses_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်တန်းများ ရရှိနိုင်ပါပြီ။"])},
      "different_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဘာသာစကားအမျိုးမျိုး"])}
    },
    "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ငါတို့အဖွဲ့"])},
    "banner_4": {
      "hashtag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မိတ်ဆက်ပေးခြင်း"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့၏အဖွဲ့နှင့်တွေ့ဆုံပါ။"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့၏အဖွဲ့တွင် မတူညီသောနောက်ခံများနှင့် ကျွမ်းကျင်မှုအစုံရှိသည့် ကျွမ်းကျင်သူများပါ၀င်ပြီး တစ်ခုစီသည် ၎င်းတို့၏ထူးခြားသောအမြင်ကို စားပွဲပေါ်တင်ဆောင်လာကြသည်။"])},
      "team_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဦးဆောင်ညွှန်ကြားရေးမှူး"])},
      "team_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အိုင်တီဖွံ့ဖြိုးတိုးတက်ရေးဒါရိုက်တာ"])},
      "team_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အုပ်ချုပ်ရေးညွှန်ကြားရေးမှူး"])},
      "team_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပညာရေးညွှန်ကြားရေးမှူး"])},
      "team_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT DevOps ခေါင်းဆောင်"])}
    }
  },
  "volunteer": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စေတနာ့ဝန်ထမ်း"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ယနေ့ စေတနာ့ဝန်ထမ်းအဖြစ် စာရင်းသွင်းပြီး ကျွန်ုပ်တို့၏ ရွှေ့ပြောင်းအလုပ်သမားများအား ၎င်းတို့၏ ပညာရေးအတွက် ကူညီပေးပါ။"])},
    "form_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဤဖောင်ကိုဖြည့်ပြီး မကြာမီ သင့်ထံ ကျွန်ုပ်တို့ ပြန်လည်ရောက်ရှိပါမည်။"])},
    "form_select_languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျေးဇူးပြု၍ သင်အဆင်ပြေ သင်ကြားနိုင်သော ဘာသာစကားများကို ရွေးချယ်ပါ။"])}
  },
  "categories": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်တန်းများ"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကျွန်ုပ်တို့နှင့်အတူ ကြီးပွားတိုးတက်ရန် ကျွန်ုပ်တို့၏အတန်းများကို စူးစမ်းပါ။"])},
    "filter_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်တန်းများကို လေ့လာပါ။"])},
    "filter_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမျိုးအစား"])},
    "filter_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တဂ်"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course Language ကို ရွေးပါ။"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စတင်လေ့လာရန် သင်တန်းတစ်ခုကို ရွေးချယ်ပါ။"])},
    "see_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])}
  },
  "courses": {
    "navigation": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အထွေထွေအသေးစိတ်"])},
      "lessons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်ခန်းစာများ"])},
      "quizzes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပဟေဠိများ"])}
    },
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စျေးနှုန်း"])},
    "free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခမဲ့"])},
    "enroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာရင်းသွင်းပါ။"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသေးစိတ်ကို ကြည့်ပါ။"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မိ"])},
    "other_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခြားဗီဒီယိုများ"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကြောင်း"])},
    "about_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အသေးစိတ်အချက်အလက်များကိုကြည့်ရှုရန် သင်ခန်းစာတစ်ခုကို ရွေးချယ်ပါ။"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အားလုံး"])}
  },
  "lessons": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လက်ရှိလေ့လာနေပါတယ်။"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဗီဒီယို"])},
    "quiz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပဟေဠိ"])},
    "quiz_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပဟေဠိများ"])},
    "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တင်ပြခြင်း။"])},
    "presentation_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တင်ပြခွင့်မရှိပါ။"])},
    "presentation_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powerpoint ကိုဒေါင်းလုဒ်လုပ်ပါ။"])}
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြှနျုပျတို့ကိုဆကျသှယျရနျ"])},
    "banner_1": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆက်သွယ်လိုက်ပါ။"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပိုမိုကြီးထွားရန် ကျွန်ုပ်တို့ကို ဆက်သွယ်ပါ။"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အီးမေးလ်မှတဆင့် ကျွန်ုပ်တို့ထံ လွတ်လပ်စွာ ဆက်သွယ်ပါ။"])},
      "subheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လိပ်စာ"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပို့ပါ။"])}
    },
    "input": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင့်နာမည်"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆက်သွယ်ရန်"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အီးမေးလ်"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မက်ဆေ့ချ်"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပို့ပါ။"])}
    }
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကိုယ်ရေးအကျဉ်း"])},
    "personal": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပုဂ္ဂိုလ်ရေး"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကိုယ်ရေးကိုယ်တာအသေးစိတ်"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နာမည်"])},
      "nric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NRIC"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖုန်းနံပါတ်"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အီးမေးလ်"])},
      "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အဖွဲ့"])},
      "groupOptions": {
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပုံသေ"])},
        "elderly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သက်ကြီးရွယ်အို"])},
        "migrant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ရွှေ့ပြောင်း"])}
      }
    },
    "company": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကုမ္ပဏီ"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကုမ္ပဏီအသေးစိတ်"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကုမ္ပဏီအမည်"])},
      "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အခန်းကဏ္ဍ"])}
    },
    "buttons": {
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အပ်ဒိတ်"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖျက်ပါ။"])}
    },
    "courses": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်တန်းများ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်တန်းများ စာရင်းသွင်းပြီးပါပြီ။"])},
      "no_course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်သည် မည်သည့်သင်တန်းများတွင် စာရင်းသွင်းထားခြင်းမရှိပါ။"])},
      "course_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်တန်းတိုးတက်မှု"])},
      "quiz_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပဟေဠိတိုးတက်မှု"])}
    },
    "assessments": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကဲဖြတ်ခြင်း။"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကဲဖြတ်မှုများ ပြီးမြောက်ခဲ့သည်။"])},
      "no_assessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်သည် မည်သည့်အကဲဖြတ်မှုမျှ မပြီးမြောက်သေးပါ။"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အဆင့်အတန်း"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နိုင်ပြီ"])},
      "view_answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အဖြေကိုကြည့်ပါ။"])}
    },
    "tutor": {
      "tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutor Tools များ"])}
    }
  },
  "login": {
    "login": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆိုင်းအင်လုပ်ခြင်း"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အီးမေးလ် သို့မဟုတ် ဖုန်းနံပါတ်"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စကားဝှက်"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လော့ဂ်အင်"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်မရှိဘူးလား။"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်ပုံတင်ပါ။"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စကားဝှက်မေ့နေပါသလား?"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လျှို့ဝှတ်နံပါတ်အားမူလအတိုင်းပြန်လုပ်သည်"])},
      "flavour3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သို့မဟုတ် လက်မှတ်ထိုးဝင်ပါ။"])},
      "hotline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စိတ်ဓာတ်ကျနေသလား။ ဤ Hotline − +65 3129 5000 သို့ခေါ်ဆိုပါ။"])}
    },
    "register": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှတ်ပုံတင်ပါ။"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖုန်းနံပါတ်"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP ရယူပါ။"])},
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်ရှိပြီးသားလား?"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆိုင်းအင်လုပ်ခြင်း"])},
      "flavour2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စာရင်းသွင်း၍မရပါ။"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဤနေရာတွင် ကျွန်ုပ်တို့ကို ဆက်သွယ်ပါ။"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လျှို့ဝှတ်နံပါတ်အားမူလအတိုင်းပြန်လုပ်သည်"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အီးမေးလ်"])},
      "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပို့ပါ။"])}
    },
    "warning": {
      "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google အကောင့်ဝင်ခြင်းကို လောလောဆယ် မရရှိနိုင်ပါ။"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook အကောင့်ဝင်ခြင်းကို လောလောဆယ် မရရှိနိုင်ပါ။"])}
    }
  },
  "navigation": {
    "header": {
      "flavour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMU နှင့် SuperINK မှပံ့ပိုးထားသည်။"])},
      "languages": {
        "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အင်္ဂလိပ်စာ"])},
        "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တရုတ်"])},
        "ms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မလေး"])},
        "ta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["တမီလ်"])},
        "my": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မြန်မာ"])},
        "si": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆင်ဟာလီ"])},
        "bn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဘင်္ဂါလီ"])}
      },
      "built_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seah Kim Cheok Construction Co. (Pte) Ltd"])}
    },
    "navbar": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အိမ်"])},
      "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြှနျုပျတို့အကွောငျး"])},
      "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["သင်တန်း"])},
      "shortcourses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပဟေဠိ"])},
      "character": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဇာတ်ကောင်"])},
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဆက်သွယ်ရန်"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကိုယ်ရေးအကျဉ်း"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အကောင့်ဝင်/အကောင့်ဖွင့်ပါ။"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ထွက်ခွာပါ။"])},
      "volunteer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["စေတနာ့ဝန်ထမ်း"])}
    },
    "footer": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လူတိုင်းနှင့် မည်သူမဆို မိမိတို့ကိုယ်ကို အစွမ်းထက်စေမည့် ကိရိယာများနှင့် အရင်းအမြစ်များကို အခမဲ့ ပံ့ပိုးပေးသည်။"])},
      "links": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["လင့်ခ်များ"])}
      },
      "contact": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ကြှနျုပျတို့ကိုဆကျသှယျရနျ"])}
      },
      "connect": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အမြန်ချိတ်ဆက်ပါ။"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["နာမည်"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အီးမေးလ်"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖော်ပြချက်"])},
        "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ပို့ပါ။"])}
      },
      "flavour": {
        "built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဖြင့်တည်ဆောက်ထားသည်။"])},
        "supported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["မှထောက်ပံ့သည်"])}
      }
    }
  },
  "extras": {
    "level1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ဤသင်တန်းတွင် အက္ခရာများ၊ ဂဏန်းများနှင့် သဒ္ဒါများကဲ့သို့သော အင်္ဂလိပ်စာအခြေခံများကို သင်ယူခြင်းဖြင့် စတင်မည်ဖြစ်ပါသည်။ ၎င်းနောက်တွင်၊ သင့်မိသားစုနှင့် သူငယ်ချင်းများနှင့် ဆက်သွယ်ရာတွင် အထောက်အကူဖြစ်စေမည့် ရိုးရှင်းသော စကားစမြည်ပြောဆိုနည်းများကို သင်လေ့လာပါမည်။ အစားအသောက် မှာယူခြင်းနှင့် ဆရာဝန်ထံ သွားခြင်းကဲ့သို့သော သာမာန်အခြေအနေများတွင် စကားပြောဆိုနည်းကိုလည်း သင်လေ့လာနိုင်မည်ဖြစ်သည်။"])},
    "level2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အဆင့် 1 မှဆက်၍ သင်သည် tenses၊ punctuations နှင့် conjunctions များကဲ့သို့သော အင်္ဂလိပ်သဒ္ဒါအကြောင်း အသေးစိတ်ကို သင်ပိုမိုလေ့လာနိုင်မည်ဖြစ်ပါသည်။ ထို့အပြင်၊ သင်၏ခံစားချက်များကိုဖော်ပြခြင်း၊ အီးမေးလ်များရေးသားခြင်းနှင့် အလုပ်နှင့်ဆက်စပ်သောအခြေအနေများတွင် စကားစမြည်ပြောဆိုခြင်းကဲ့သို့သော ကိုယ်ရေးကိုယ်တာနှင့် အလုပ်ဆက်တင်နှစ်ခုစလုံးတွင် စကားစမြည်ပြောဆိုခြင်းစွမ်းရည်ကို သင်ပိုမိုလေ့လာနိုင်မည်ဖြစ်ပါသည်။"])},
    "level3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["အဆင့် 3 သို့ရောက်သောအခါ၊ ဝိဘတ်များ၊ ကြိယာများကဲ့သို့သော ပိုမိုရှုပ်ထွေးသောအကြောင်းအရာများကို ကိုင်တွယ်ဖြေရှင်းပြီးနောက် စာဖတ်ခြင်းနှင့် နားလည်နိုင်မှုတို့အတွက် မရှိမဖြစ်လိုအပ်သောစွမ်းရည်များကို စုဆောင်းခြင်းသို့ ရောက်ရှိလာပါသည်။ နောက်ဆုံးတွင်၊ ကျွန်ုပ်တို့သည် နောက်ဆုံးပြန်လည်သုံးသပ်သည့်အစည်းအဝေးတွင် သင်သင်ယူခဲ့သမျှကို နိဂုံးချုပ်ပြီး သင်၏အနာဂတ်အင်္ဂလိပ်စာလေ့လာမှုများအတွက် သင့်အားကူညီရန် အကြံပြုချက်များကို ကမ်းလှမ်းပါမည်။"])}
  }
}